import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Light from "../icons/half-2-light.svg";

const Thankyou = () => {
  return (
    <Layout>
      <Seo
        title={`Intranet 80.20: richiedi la tua demo gratuita`}
        description={`Stai prendendo in considerazione Intranet 80.20? Richiedi la tua demo live personalizzata per scoprire come semplificare la tua comunicazione aziendale!`}
        robots={`noindex`}
      />

      <div className="hero">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <div className="pb-3 text-center">
                <h1 className="hero__title">GRAZIE!</h1>
                <p>Ti contatteremo al più presto.</p>
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <Half8Solid className="ico-svg ico-half-8-solid position-static" />
                  <Half2Light className="ico-svg ico-half-2-light position-static d-block ms-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Thankyou;
